import React, { useMemo } from 'react';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import Button, { themes as ButtonThemes } from '../../UI/Button/Button';
import useFormData from '../../../hooks/useFormData';

const options = [
  { label: 'GP', value: 'GP' },
  { label: 'Consultant', value: 'Consultant' },
  { label: 'Physiotherapy', value: 'Physiotherapy' },
  { label: 'Other', value: 'Other' }
];


const BookStep2 = ({ onSubmit, data, form, ...other }) => {  
  const [ formData, setFormData ] = useFormData(data);
  const selectedLocation = useMemo(() => {
    return form.find(({ formData }) => formData?.centre)?.formData?.centre;
  }, [ form ]);
  let showNext = false;

  if(
    (formData.clinicType && formData.clinicType !== 'GP')
    || 
    (formData.clinicType === 'GP' && selectedLocation !== 'Surrey')
  ) {
    showNext = true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  return (
    <Step title="Clinic type" selectedValue={data} {...other}>
      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}> 
        <Form.Fieldset label="Select clinic type">
          {options.map((option, index) => (
              <Form.Radio 
                label={option.label} 
                name="clinicType" 
                id={`clinicType${index}`} 
                value={option.value} 
                required 
                checked={formData?.clinicType === option.value}
                key={index}
              />
          ))}
        </Form.Fieldset>

        <Form.Row>
          {formData?.clinicType === 'GP' && selectedLocation === 'Surrey' &&
            <>
              <p>GP appointments can be made directly here. If you aren't already registered please use the code WELCOMEPRIME.</p>
              <Button theme={ButtonThemes.PRIMARY} to="https://www.patientbooking.co.uk/primehealth/#/register?inviteCode=WELCOMEPRIME">
                Book appointment
              </Button>
            </>            
          }

          {formData?.clinicType === 'Consultant' &&
            <>
              <p>Please complete the details below and a member of our team will contact you to confirm your appointment options. If you know which consultant you want an appointment with, please tell us here:</p>

              <Form.Input
                theme={fieldThemes.PRIMARY_FILLED}
                label="Consultant name"
                name="consultantName"
                value={formData?.consultantName}
                />
            </>
          }

          {formData?.clinicType === 'Physiotherapy' &&
            <>
              <p>Please complete the details below and a member of our team will contact you to confirm your appointment options. If you know which physiotherapist you want an appointment with, please tell us here:</p>

              <Form.Input
                theme={fieldThemes.PRIMARY_FILLED}
                label="Physiotherapist name"
                name="physiotherapistName"
                value={formData?.physiotherapistName}
                />
            </>
          }

          {formData?.clinicType === 'Other' &&
            <>
              <p>Please help us by telling us which of our services you are looking for and a member of our team will be in touch to confirm your options:</p>

              <Form.TextArea
                theme={fieldThemes.PRIMARY_FILLED}
                label="Service details"
                name="serviceDetails"
                value={formData?.serviceDetails}
                rows="4"
                required
                />
            </>
          }
        </Form.Row>

        { showNext && 
          <Button theme={ButtonThemes.OUTLINE} type="submit">
            Next
          </Button>
        }
      </Form>
    </Step>
  )
};


export default BookStep2;